<template>
  <div style="width:100%">
    <v-card width="400" style="margin-left:auto;margin-right:auto;" outlined>
      <v-card-title>
        <h2>Reset password</h2>
      </v-card-title>
      <v-card-text>
        <p>Set here your new password.</p>
        <v-form @submit="resetPassword">
          <v-text-field type="password" label="New password" v-model="password"></v-text-field>
          <v-text-field type="password" label="Repeat new password" v-model="password_repeat"></v-text-field>
          <v-btn type="submit" depressed>Reset</v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { auth } from "../schmucklicloud";
export default {
  mounted() {},
  data() {
    return {
      password: "",
      password_repeat: ""
    };
  },
  methods: {
    getUrlParameter(name, url) {
      if (!url) url = location.href;
      // eslint-disable-next-line
      name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
      var regexS = "[\\?&]" + name + "=([^&#]*)";
      var regex = new RegExp(regexS);
      var results = regex.exec(url);
      return results == null ? null : results[1];
    },
    async resetPassword(e) {
      e.preventDefault();
      var reset_token = this.getUrlParameter("token", window.location.href);
      if (this.password === this.password_repeat) {
        var response = await auth.updateResetPassword(
          reset_token,
          this.password
        );
        if (response.isOK) {
          this.$notify.toast("New password has been set.");
          this.$router.replace("/login");
        } else {
          this.$notify.toast(response.message);
          this.password = "";
          this.password_repeat = "";
        }
      } else {
        this.$notify.toast("Passwords do not match.");
        this.password = "";
        this.password_repeat = "";
      }
    }
  }
};
</script>

<style>
</style>